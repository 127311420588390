import { useCallback, useRef } from 'react'
import { useUpdate } from 'react-use'

export const useStateTools = <T extends object>(
  initialState: T = {} as T
): [T, () => T, (patch: Partial<T>) => void] => {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof initialState !== 'object') {
      console.error(
        'useGetSetState initial state must be an object.'
      )
    }
  }

  const update = useUpdate()
  const state = useRef<T>({ ...(initialState as object) } as T)
  const get = useCallback(() => state.current, [])
  const set = useCallback(
    (patch: Partial<T>) => {
      if (!patch) {
        return
      }
      state.current = Object.assign({}, state.current, patch)
      update()
    },
    [update]
  )

  return [state.current, get, set]
}

export default useStateTools
