import React, { FC, PropsWithChildren, useMemo } from 'react'
import {
  MessagingManagerContext,
  MessagingManagerContextProps,
} from './context.ts'

interface MessagingManagerProviderProps {
  apiBaseUrl: string
}

export const MessagingManagerProvider: FC<
  PropsWithChildren<MessagingManagerProviderProps>
> = ({ children, apiBaseUrl }) => {
  const value = useMemo((): MessagingManagerContextProps => {
    return {
      apiBaseUrl,
    }
  }, [apiBaseUrl])

  return (
    <MessagingManagerContext.Provider value={value}>
      {children}
    </MessagingManagerContext.Provider>
  )
}
