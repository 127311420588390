import { useSessionContext } from '@pogokid/supabase/react'
import React, { FC, useMemo } from 'react'
import { useCurrentUser, useUserProfile } from '../entityData'
import {
  ConnectionManagerContext,
  ConnectionManagerContextProps,
} from './context.tsx'

interface ConnectionManagerProviderProps {
  apiBaseUrl: string
}

export const ConnectionManagerProvider: FC<
  React.PropsWithChildren<ConnectionManagerProviderProps>
> = ({ children, apiBaseUrl }) => {
  const { isLoading: isSessionLoading, session } =
    useSessionContext()
  const currentUser = useCurrentUser()
  const userProfile = useUserProfile()

  const value = useMemo((): ConnectionManagerContextProps => {
    const { value: user } = currentUser
    const { value: profile } = userProfile
    const isLoading = Boolean(
      currentUser.loading || userProfile.loading
    )
    return {
      apiBaseUrl,
      hasUserDetails: !isLoading && !!user,
      hasAccess: user ? !!user.invite_id : null,
      isLoadingUserDetails: session
        ? isLoading
        : isSessionLoading,
      errors: [currentUser.error, userProfile.error].filter(
        Boolean
      ),
      userId: user?.id,
      user,
      profileId: profile?.id,
      username: profile?.handle ?? 'me',
      profile,
      refetch() {
        currentUser.refetch()
        userProfile.refetch()
      },
    }
  }, [
    currentUser,
    userProfile,
    apiBaseUrl,
    session,
    isSessionLoading,
  ])

  return (
    <ConnectionManagerContext.Provider value={value}>
      {children}
    </ConnectionManagerContext.Provider>
  )
}
