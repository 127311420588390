import { createContext, useContext } from 'react'

interface MessagingManagerProviderState {}

export interface MessagingManagerContextProps
  extends MessagingManagerProviderState {
  apiBaseUrl: string
}

export const MessagingManagerContext =
  createContext<MessagingManagerContextProps>({} as any)

export function useMessagingManager() {
  return useContext(MessagingManagerContext)
}
