import { ButtonGroup, Stack, Toolbar } from '@pogokid/mui'
import {
  RichTextBoldButton,
  RichTextBulletListButton,
  RichTextItalicButton,
  RichTextOrderedListButton,
  RichTextRedoButton,
  RichTextStrikethroughButton,
  RichTextUndoButton,
} from './menuBarButtons'

export interface RichTextMenuBarProps {}
export const RichTextMenuBar = ({}: RichTextMenuBarProps) => {
  return (
    <Toolbar disableGutters sx={{ mb: 1 }}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        sx={{ gap: '.5rem' }}
        useFlexGap
      >
        <ButtonGroup>
          <RichTextUndoButton />
          <RichTextRedoButton />
        </ButtonGroup>
        <ButtonGroup>
          <RichTextBoldButton />
          <RichTextItalicButton />
          <RichTextStrikethroughButton />
        </ButtonGroup>
        <ButtonGroup>
          <RichTextBulletListButton />
          <RichTextOrderedListButton />
        </ButtonGroup>
      </Stack>
    </Toolbar>
  )
}
