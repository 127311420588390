import { alpha, styled } from '@pogokid/mui'
import {
  EditorComponent,
  Remirror,
  useDocChanged,
  useHelpers,
  useRemirror,
} from '@remirror/react'
import { PropsWithChildren, useCallback } from 'react'
import {
  AnyExtension,
  isDocNodeEmpty,
  type RemirrorContentType,
  type RemirrorJSON,
} from 'remirror'
import {
  BoldExtension,
  BulletListExtension,
  ItalicExtension,
  OrderedListExtension,
  StrikeExtension,
} from 'remirror/extensions'
import { RichTextMenuBar } from './RichTextMenuBar'

const StyledEditorContent = styled('div')(({ theme }) => ({
  '.ProseMirror': {
    whiteSpace: 'pre-wrap',
    outlineStyle: 'solid',
    outlineColor:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.common.white, 0.25)
        : alpha(theme.palette.common.black, 0.25),
    outlineWidth: 1,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'rgb(0 0 0 / 7%) 0px 1px 1px',
    padding: theme.spacing(0.5),
    transition: theme.transitions.create('outline', {
      duration: 100,
    }),

    '&.ProseMirror-focused': {
      outlineColor: theme.palette.primary.main,
      outlineWidth: 2,
    },

    p: {
      margin: '.5rem .5rem',
    },
  },
}))

export interface RichTextEditorProps {
  initialContent?: RemirrorContentType
  onFocus?: (ev: Event) => void
  onBlur?: (ev: Event) => void
  onChange?: (data: {
    json: RemirrorJSON | null
    isEmpty: boolean
  }) => void
}

const extensions = (): AnyExtension[] => [
  new BoldExtension({}),
  new ItalicExtension(),
  new BulletListExtension({}),
  new OrderedListExtension(),
  new StrikeExtension(),
]

export const RichTextEditor = ({
  children,
  onFocus,
  onBlur,
  ...props
}: PropsWithChildren<RichTextEditorProps>) => {
  const { manager } = useRemirror({
    extensions,
  })
  const onRemirrorFocus = useCallback(
    (_: unknown, ev: Event) => {
      onFocus?.(ev)
    },
    [onFocus]
  )
  const onRemirrorBlur = useCallback(
    (_: unknown, ev: Event) => {
      onBlur?.(ev)
    },
    [onBlur]
  )

  return (
    <StyledEditorContent>
      <Remirror
        manager={manager}
        initialContent={props.initialContent}
        onFocus={onRemirrorFocus}
        onBlur={onRemirrorBlur}
      >
        <RichTextMenuBar />
        <EditorComponent />
        <RichTextEvents {...props} />
        {children}
      </Remirror>
    </StyledEditorContent>
  )
}

function RichTextEvents({ onChange }: RichTextEditorProps) {
  const { getJSON } = useHelpers()
  useDocChanged(
    useCallback(
      ({ state }) => {
        const isEmpty = isDocNodeEmpty(state.doc)
        onChange?.({
          isEmpty,
          json: isEmpty ? null : getJSON(state),
        })
      },
      [onChange, getJSON]
    )
  )
  return null
}
