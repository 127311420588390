import type { RemirrorJSON } from 'remirror'
import ReactDOMServer from 'react-dom/server'
import { createElement } from 'react'
import { RichTextRenderer } from './RichTextRenderer'

export async function renderHTMLFromDoc(doc: RemirrorJSON) {
  const stream = await ReactDOMServer.renderToReadableStream(
    createElement(RichTextRenderer, { node: doc })
  )
  const reader = stream.getReader()
  let html = ''
  while (true) {
    const { value, done } = await reader.read()
    if (value) {
      html += new TextDecoder().decode(value)
    }
    if (done) {
      return html
    }
  }
}
