import { Button } from '@pogokid/mui'
import { useActive, useCommands } from '@remirror/react'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough'
import CodeIcon from '@mui/icons-material/Code'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'

export interface RichTextMenuButtonProps {}
export const RichTextBoldButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleBold, focus } = useCommands()
    const active = useActive()
    return (
      <Button
        onClick={() => {
          toggleBold()
          focus()
        }}
        disabled={!toggleBold.enabled()}
        variant={active.bold() ? 'contained' : 'outlined'}
      >
        <FormatBoldIcon />
      </Button>
    )
  }
export const RichTextItalicButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleItalic, focus } = useCommands()
    const active = useActive()
    return (
      <Button
        onClick={() => {
          toggleItalic()
          focus()
        }}
        disabled={!toggleItalic.enabled()}
        variant={active.italic() ? 'contained' : 'outlined'}
      >
        <FormatItalicIcon />
      </Button>
    )
  }
export const RichTextStrikethroughButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleStrike, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          toggleStrike()
          focus()
        }}
        disabled={!toggleStrike.enabled()}
        variant={active.strike() ? 'contained' : 'outlined'}
      >
        <FormatStrikethroughIcon />
      </Button>
    )
  }
export const RichTextCodeButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleCode, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          toggleCode()
          focus()
        }}
        disabled={!toggleCode.enabled()}
        variant={active.code() ? 'contained' : 'outlined'}
      >
        <CodeIcon />
      </Button>
    )
  }
export const RichTextCodeBlockButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleCodeBlock, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          toggleCodeBlock()
          focus()
        }}
        variant={active.codeBlock() ? 'contained' : 'outlined'}
      >
        <CodeIcon />
      </Button>
    )
  }
export const RichTextBulletListButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleBulletList, focus } = useCommands()
    const active = useActive()
    return (
      <Button
        onClick={() => {
          toggleBulletList()
          focus()
        }}
        variant={active.bulletList() ? 'contained' : 'outlined'}
      >
        <FormatListBulletedIcon />
      </Button>
    )
  }
export const RichTextOrderedListButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleOrderedList, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          toggleOrderedList()
          focus()
        }}
        variant={active.orderedList() ? 'contained' : 'outlined'}
      >
        <FormatListNumberedIcon />
      </Button>
    )
  }
export const RichTextQuoteButton =
  ({}: RichTextMenuButtonProps) => {
    const { toggleBlockquote, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          toggleBlockquote()
          focus()
        }}
        variant={active.blockquote() ? 'contained' : 'outlined'}
      >
        <FormatQuoteIcon />
      </Button>
    )
  }
export const RichTextHorizontalRuleButton =
  ({}: RichTextMenuButtonProps) => {
    const { setHorizontalRule, focus } = useCommands()
    const active = useActive()

    return (
      <Button
        onClick={() => {
          setHorizontalRule()
          focus()
        }}
      >
        <HorizontalRuleIcon />
      </Button>
    )
  }
export const RichTextUndoButton =
  ({}: RichTextMenuButtonProps) => {
    const { undo, focus } = useCommands()
    return (
      <Button
        onClick={() => {
          undo()
          focus()
        }}
        disabled={!undo.enabled()}
      >
        <UndoIcon />
      </Button>
    )
  }
export const RichTextRedoButton =
  ({}: RichTextMenuButtonProps) => {
    const { redo, focus } = useCommands()
    return (
      <Button
        onClick={() => {
          redo()
          focus()
        }}
        disabled={!redo.enabled()}
      >
        <RedoIcon />
      </Button>
    )
  }
