import type { RemirrorJSON } from 'remirror'
import {
  Callout,
  CodeBlock,
  Doc,
  Heading,
  MarkMap,
  RemirrorRenderer,
  TextHandler,
} from '@remirror/react-renderer'

interface RichTextRendererProps {
  node: RemirrorJSON
  className?: string
}
const richTextTypeMap: MarkMap = {
  blockquote: 'blockquote',
  bulletList: 'ul',
  callout: Callout,
  doc: Doc,
  heading: Heading,
  listItem: 'li',
  paragraph: 'p',
  horizontalRule: 'hr',
  image: 'img',
  hardBreak: 'br',
  codeBlock: CodeBlock,
  orderedList: 'ol',
  text: TextHandler,
}

export function RichTextRenderer({
  node,
  className,
}: RichTextRendererProps) {
  return (
    <RemirrorRenderer json={node} typeMap={richTextTypeMap} />
  )
}
