import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { messageJsonSchema, MessageSchema } from './index'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateMessage =
  compileValidationSchema<MessageSchema>(
    'MessageSchema',
    compileJsonSchema(messageJsonSchema),
    {
      shouldCleanProperties: (propertyName) =>
        propertyName !== 'rich_body',
    }
  )

export const messageProperties = schemaProperties(
  messageJsonSchema
)

export const cleanMessageProperties =
  makePropertyCleaner<MessageSchema>(messageProperties)
