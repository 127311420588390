import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import {
  createEventEmitter,
  useStateTools,
} from '@pogokid/react-hooks'
import {
  AsyncSnackbarItemOptions,
  SnackbarActionEvent,
  SnackbarItemAction,
  SnackbarItemOptions,
  SnackbarItemState,
} from './types'
import { SnackbarItem } from './SnackbarItem'
import { Snackbar, SnackbarOrigin, styled } from '@mui/material'
import { getAllLogger } from '@pogokid/log'

const log = getAllLogger('mui:SnackbarProvider')

interface SnackbarProviderState {
  snacks: SnackbarItemState[]
}

interface SnackbarContextProps {
  addOnAction: (
    listener: (event: CustomEvent<SnackbarActionEvent>) => void
  ) => void
  removeOnAction: (
    listener: (event: CustomEvent<SnackbarActionEvent>) => void
  ) => void

  asyncSnack<T>(options: AsyncSnackbarItemOptions<T>): Promise<T>

  addSnack(snack: SnackbarItemOptions): void

  addSnackAction(
    snackId: string,
    action: SnackbarItemAction
  ): void
}

interface SnackbarProviderProps {}

const SnackbarContext = createContext<SnackbarContextProps>(
  {} as never
)

const { useEventEmitter } = createEventEmitter()

export function useSnackbarContext() {
  return useContext(SnackbarContext)
}

const SnackStack = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  rowGap: '.5rem',
  width: '90vw',
  [theme.breakpoints.up('md')]: {
    width: '360px',
  },
}))

export const SnackbarProvider: FC<
  PropsWithChildren<SnackbarProviderProps>
> = ({ children }) => {
  const {
    dispatchEvent,
    addEventListener,
    removeEventListener,
  } = useEventEmitter<SnackbarActionEvent>('snack_action')
  const [state, getState, setState] =
    useStateTools<SnackbarProviderState>({ snacks: [] })
  const value = useMemo((): SnackbarContextProps => {
    function addSnack(snack: SnackbarItemOptions) {
      const state = getState()
      const snacks = state.snacks.filter(
        ({ id }) => id !== snack.id
      )
      setState({
        snacks: [
          ...snacks,
          {
            ...snack,
            createdAt: Date.now(),
            isSticky: !!snack.isSticky,
            isOpen: true,
            isDismissed: false,
            actions: [],
          },
        ],
      })
    }

    return {
      addOnAction: addEventListener,
      removeOnAction: removeEventListener,
      asyncSnack<T>(options: AsyncSnackbarItemOptions<T>) {
        if (options.waiting) {
          addSnack({
            severity: 'info',
            ...options.waiting,
            id: options.id,
          })
        }
        return options.promise.then(
          (v) => {
            addSnack({
              severity: 'success',
              ...options.success,
              id: options.id,
            })
            return v
          },
          (e) => {
            addSnack({
              severity: 'error',
              ...options.error,
              id: options.id,
            })
            throw e
          }
        )
      },
      addSnack,
      addSnackAction(
        snackId: string,
        action: SnackbarItemAction
      ) {
        const state = getState()
        const snackIndex = state.snacks.findIndex(
          ({ id }) => id === snackId
        )
        if (snackIndex > -1) {
          const snack = state.snacks[snackIndex]
          const existingAction = snack.actions.find(
            ({ id }) => id === action.id
          )
          if (!existingAction) {
            const newState = [...state.snacks]
            newState.splice(snackIndex, 1, {
              ...snack,
              actions: [...snack.actions, action],
            })
            setState({ snacks: newState })
          }
        }
      },
    }
  }, [getState, setState])

  /**
   * The snack is told to close for some reason
   */
  const onClose = useCallback(
    (snackId: string) => {
      log.verbose('Snack closing', snackId)
      const state = getState()
      setState({
        snacks: state.snacks.map((snack) => {
          if (snack.id === snackId) {
            return { ...snack, isOpen: false }
          } else {
            return snack
          }
        }),
      })
    },
    [getState, setState]
  )

  /**
   * The snack close transition has completed
   * and we should remove it from the list
   */
  const onExited = useCallback(
    (snackId: string) => {
      log.verbose('Snack exited', snackId)
      const state = getState()
      setState({
        snacks: state.snacks.filter(
          (snack) => snack.id !== snackId
        ),
      })
    },
    [getState, setState]
  )

  const onAction = useCallback(
    (snackId: string, action: SnackbarItemAction) => {
      log.verbose('Snack action', snackId, action)
      dispatchEvent({ snackId, action })
    },
    [dispatchEvent]
  )
  const origin = useMemo((): SnackbarOrigin => {
    return {
      vertical: 'bottom',
      horizontal: 'center',
    }
  }, [])

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={state.snacks.length > 0}
        autoHideDuration={null}
        transitionDuration={0}
        anchorOrigin={origin}
        data-testid="snackbar"
      >
        <SnackStack>
          {state.snacks
            .toReversed()
            .slice(0, 3)
            .map((snack) => (
              <SnackbarItem
                key={snack.id}
                snack={snack}
                onClose={onClose}
                onExited={onExited}
                onAction={onAction}
              />
            ))}
        </SnackStack>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
