import {
  CacheProvider,
  createEmotionCache,
  CssBaseline,
  DateProvider,
  EmotionCache,
  SnackbarProvider,
  ThemeProvider,
  useMediaQuery,
} from '@pogokid/mui'
import { createClient } from '@supabase/supabase-js'
import {
  SessionContextProvider,
  SessionContextProviderProps,
} from '@supabase/auth-helpers-react'
import * as React from 'react'
import { PropsWithChildren } from 'react'
import { soniqStylesTheme } from '~/theme'
import { AppUiProvider } from '~m/AppUi/context/AppUiProvider'
import { TanstackQueryProvider } from '~m/AppUi/context/TanstackQueryProvider'
import { getPublicConfig } from '~/config.ts'
import { ErrorBoundary } from '~m/AppUi/components/ErrorBoundary.tsx'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface LayoutProps {
  emotionCache?: EmotionCache
  initialSession?: SessionContextProviderProps['initialSession']
}

const config = getPublicConfig()
const supabase = createClient(
  config.supabaseUrl,
  config.supabaseAnonKey
)

function SupabaseProvider({
  children,
  initialSession,
}: PropsWithChildren<{
  initialSession?: SessionContextProviderProps['initialSession']
}>) {
  return (
    <SessionContextProvider
      supabaseClient={supabase}
      initialSession={initialSession}
    >
      {children}
    </SessionContextProvider>
  )
}

function ThemeWithDarkMode({ children }: PropsWithChildren) {
  const prefersDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)'
  )
  const theme = React.useMemo(
    () => soniqStylesTheme(prefersDarkMode ? 'dark' : 'light'),
    [prefersDarkMode]
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default function ClientLayout({
  children,
  ...props
}: PropsWithChildren<LayoutProps>) {
  const { emotionCache = clientSideEmotionCache } = props
  return (
    <CacheProvider value={emotionCache}>
      <SupabaseProvider initialSession={props.initialSession}>
        <TanstackQueryProvider>
          <DateProvider>
            <SnackbarProvider>
              <AppUiProvider>
                <ThemeWithDarkMode>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </ThemeWithDarkMode>
              </AppUiProvider>
            </SnackbarProvider>
          </DateProvider>
        </TanstackQueryProvider>
      </SupabaseProvider>
    </CacheProvider>
  )
}
