import { Optional } from '@pogokid/util'
import {
  ContactWithId,
  ProfileWithId,
} from '@soniq/schema-connection'
import { createContext } from 'react'

export interface ConnectionManagerContextState {
  /**
   * Is authenticated and has user and settings
   */
  hasUserDetails: boolean
  /**
   * User has an invite and is allowed on the platform
   */
  hasAccess: boolean | null
  isLoadingUserDetails: boolean
  errors: Optional<Error>[]
  userId: Optional<string>
  user: Optional<ContactWithId>
  profileId: Optional<string>
  profile: Optional<ProfileWithId>
  username: string
}

export interface ConnectionManagerContextProps
  extends ConnectionManagerContextState {
  apiBaseUrl: string

  refetch(): void
}

export const ConnectionManagerContext =
  createContext<ConnectionManagerContextProps>({} as any)
