import { setEntityStoreMutations } from '@soniq/public-manager'
import { MessageSchema } from '@soniq/schema-messaging'
import { messageKeys } from './keys'
import { QueryClient } from '@tanstack/react-query'
import { SupabaseClient } from '@supabase/supabase-js'
import {
  messageStore,
  MessageStore,
} from '@soniq/public-resource-messaging'

export function initMessagingManagerEntities(
  apiBaseUrl: string,
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Message
  setEntityStoreMutations<
    MessageSchema,
    typeof messageKeys,
    MessageStore
  >({
    apiBaseUrl,
    queryClient,
    entityKeys: messageKeys,
    store: messageStore,
    db,
  })
}
