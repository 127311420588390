import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  MessageSchema,
  validateMessage,
} from '@soniq/schema-messaging'
import {
  isRemirrorJSON,
  renderHTMLFromDoc,
} from '@pogokid/mui-richtext'
import { SupabaseClient } from '@supabase/supabase-js'
import { OmitCreated, SchemaWithId } from '@soniq/schema'
import { UpsertSchema } from '@soniq/public-resource'

export class MessageStore extends SupabaseReadWriteStore<
  'message',
  MessageSchema
> {
  default_order = { created_at: { ascending: false } }

  static new() {
    return new MessageStore('message', validateMessage)
  }

  async create(
    db: SupabaseClient,
    userId: string,
    data: OmitCreated<MessageSchema>
  ) {
    return super.create(
      db,
      userId,
      await this.bodyFromRichBody(data)
    )
  }

  async update(
    db: SupabaseClient,
    data: SchemaWithId<Partial<MessageSchema>>
  ) {
    return super.update(db, await this.bodyFromRichBody(data))
  }

  async upsert(
    db: SupabaseClient,
    data: UpsertSchema<MessageSchema>[]
  ) {
    return super.upsert(
      db,
      await Promise.all(
        data.map((d) => this.bodyFromRichBody(d))
      )
    )
  }

  private async bodyFromRichBody<
    T extends Pick<MessageSchema, 'body' | 'rich_body'>
  >(data: T) {
    if (isRemirrorJSON(data.rich_body)) {
      return {
        ...data,
        body: await renderHTMLFromDoc(data.rich_body),
      }
    }
    return data
  }
}
