import wasm from '../.wasm/wasm_public_manager_payments_bg.wasm?url'
import init, {
  create_invoices_from_lesson_schedules,
  openapi_json,
} from '../.wasm/wasm_public_manager_payments'

export type * from '../.wasm/wasm_public_manager_payments'

export type WasmModule = Awaited<ReturnType<typeof initWasm>>

export async function initWasm() {
  await init({ module_or_path: wasm })
  return {
    openapi: JSON.parse(openapi_json()),
    createInvoicesFromLessonSchedules:
      create_invoices_from_lesson_schedules,
  }
}
